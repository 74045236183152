import { useLocation, useMatches } from '@remix-run/react';
import { HandleStructuredData } from '../../lib/structuredData';

function isHandleStructuredData(
  handle: unknown
): handle is HandleStructuredData {
  return (
    handle !== undefined &&
    (handle as HandleStructuredData).structuredData !== undefined &&
    typeof (handle as HandleStructuredData).structuredData === 'function'
  );
}

// Ripped from remix-utils
export function StructuredData() {
  const location = useLocation();

  const structuredData = useMatches().flatMap((match, index, matches) => {
    if (isHandleStructuredData(match.handle)) {
      const result = match.handle.structuredData({
        id: match.id,
        data: match.data,
        params: match.params,
        location,
        parentsData: matches.slice(0, index).map((m) => m.data),
      });
      if (result) return result;
    }

    return [];
  });

  if (structuredData.length === 0) {
    return null;
  }

  const renderedScript =
    structuredData.length === 1
      ? JSON.stringify(structuredData[0])
      : JSON.stringify(structuredData);

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: renderedScript,
      }}
    />
  );
}
