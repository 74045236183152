import { UIMatch, useMatches, useSearchParams } from '@remix-run/react';
import { getApp } from '../lib/env';
import { RootHandle } from './root/types';

type UseCrisp = {
  isCrispChatEnabled: boolean;
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $crisp: any;
  }
}

export const useCrispChat = (): UseCrisp => {
  const matches = useMatches() as UIMatch<unknown, RootHandle>[];
  const [searchParams] = useSearchParams();
  if (searchParams.get('disable-crisp')) {
    return { isCrispChatEnabled: false };
  }

  const hideCrisp = !!matches.find((x) => x.handle?.hideCrisp);

  const isCrispChatEnabled = !hideCrisp && getApp() !== 'crypto';

  return {
    isCrispChatEnabled,
  };
};

export const CrispChat = () => {
  const { isCrispChatEnabled } = useCrispChat();

  if (!isCrispChatEnabled) {
    return null;
  }

  // Here we defer loading crisp until after the document is fully loaded
  // in my testing at webpagetest.org, this is better than just async, or defer, or DOMContentLoaded
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          window.$crisp=[];
          window.CRISP_WEBSITE_ID="83d8ebcc-54a3-4002-abed-ec751cf1dc3c";
          document.onreadystatechange = () => {
            if (document.readyState === "complete") {
              (function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.defer=true;d.getElementsByTagName("head")[0].appendChild(s);})();
            }
          };
          `,
      }}
    />
  );
};
