import { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  isRouteErrorResponse,
  useLoaderData,
  useMatches,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { isbot } from 'isbot';
import type { Corporation, WithContext } from 'schema-dts';
import { CrispChat } from './components/CrispChat';
import LoginRequiredPage from './components/LoginRequiredPage';
import NotFoundPage from './components/NotFoundPage';
import PermissionDeniedPage from './components/PermissionDeniedPage';
import { Styles, StylesType } from './components/Styles';
import { DeviceId } from './components/root/DeviceId';
import { EventTracking } from './components/root/EventTracking';
import { FirstPromoter } from './components/root/FirstPromoter';
import { FixGoogleTranslate } from './components/root/FixGoogleTranslate';
import { GoogleAdsCookie } from './components/root/GoogleAdsCookie';
import { GoogleAnalytics4 } from './components/root/GoogleAnalytics4';
import { MetaPixel } from './components/root/MetaPixel';
import { StructuredData } from './components/root/StructuredData';
import { RootHandle } from './components/root/types';
import { SiteLayout } from './components/site/SiteLayout';
import { AuthenticatedUser, requestAuthOptional } from './lib/auth.server';
import { HandleStructuredData } from './lib/structuredData';
import { HttpApiRequestError } from './apiWebbase/HttpApiRequestError';
import { PrefetchResources } from './lib/prefetch-resources';

export const WebbaseProdHost = 'api.gopipeline.io';
export const WebsocketProdHost = 'ws.gopipeline.io';

const isProd = process.env.NODE_ENV === 'production';
const isServer = typeof window === 'undefined';

export type RootLoaderData = {
  authenticatedUser: AuthenticatedUser | null;
  isBot: boolean;
  env: { [key: string]: string | undefined };
};

export const links: LinksFunction = () => {
  return PrefetchResources.map((resource) => {
    if (resource.as === 'font') {
      return {
        rel: resource.rel,
        href: resource.path,
        crossOrigin: 'anonymous',
        as: 'font',
      };
    }

    return {
      rel: resource.rel,
      href: resource.path,
    };
  });
};

export const handle: HandleStructuredData = {
  structuredData: () => {
    const postSchema: WithContext<Corporation> = {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      name: 'SyncWith',
      url: 'https://syncwith.com',
      logo: 'https://syncwith.com/images/syncwith-logo.png',
      sameAs: [
        'https://twitter.com/syncwithhq',
        'https://syncwith.com',
        'https://linkedin.com/company/syncwith-data',
      ],
    };

    return postSchema;
  },
};

export const loader = async (
  args: LoaderFunctionArgs
): Promise<RootLoaderData> => {
  const { request } = args;
  const authAndSession = await requestAuthOptional(request);

  const isBot = isbot(request.headers.get('User-Agent'));

  return {
    isBot,
    authenticatedUser: authAndSession,
    env: {
      WEBBASE_HOST: process.env.WEBBASE_HOST,
      SENTRY_ADDON_DSN: process.env.SENTRY_ADDON_DSN,
      AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
      GOOGLE_ANALYTICS_ID: process.env.GOOGLE_ANALYTICS_ID,
      APP: 'syncwith',
      NODE_ENV: process.env.NODE_ENV || 'development',
      USER_ID: authAndSession?.auth.id,
      USER_EMAIL: authAndSession?.auth.email,
      IS_BOT: isBot ? '1' : undefined,
      STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
      WEBSOCKET_HOST: process.env.WEBSOCKET_HOST,
    },
  };
};

function Document({
  children,
  data,
  styles,
  title,
}: {
  children: React.ReactNode;
  data: RootLoaderData;
  styles?: StylesType[];
  title?: string;
}) {
  const { isBot } = data;

  // Routes can opt-in to exclude remix scripts (eg no hydration or client side react app)
  const fullHeight = !!useMatches()
    .filter((m) => m.handle)
    .find((m) => {
      const h = m.handle as RootHandle;
      return h.fullHeight === true;
    });

  return (
    <html lang="en" className={fullHeight ? 'h-full' : undefined}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {title && <title>{title}</title>}
        <Meta />
        <meta
          name="facebook-domain-verification"
          content="st5u69ixhsjkx6pphma0167sjjkp7a"
        />
        <Links />
        <link rel="icon" href="/favicon.png" type="image/png" />
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(data.env)}`,
          }}
        />
        <EventTracking />
        <DeviceId />
        <GoogleAnalytics4 />
        <GoogleAdsCookie />
        <FixGoogleTranslate />
        <FirstPromoter />
        {/* install a dummy require() function to satisfy connection-string-parser */}
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `function require() { throw new Error('this is a stub to satisfy esbuild; do not call'); }`,
          }}
        />
        <CrispChat />
        {styles && (
          <>
            {styles.map((css, index) => (
              <link key={index} rel={css.rel} href={css.href} />
            ))}
          </>
        )}
        <StructuredData />
      </head>
      <body
        className={
          fullHeight ? 'h-full overflow-y-scroll' : 'overflow-y-scroll'
        }
      >
        {children}

        {!isBot && <Scripts />}
        <MetaPixel />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  return (
    <Document data={data}>
      <Outlet />
    </Document>
  );
}

export default App;

const WebbaseHost = isServer ? process.env.WEBBASE_HOST : 'api.gopipeline.io';

const getPlaceholderLoaderData = () => {
  // we can't use `useLoaderData` if the current error occured in a LoaderFunction, so we hack in some values instead
  const data: RootLoaderData = {
    isBot: false,
    authenticatedUser: null,
    env: {
      NODE_ENV: isServer
        ? process.env.NODE_ENV || 'development'
        : 'development',
      APP: 'syncwith',
      WEBBASE_HOST: WebbaseHost,
      GOOGLE_ANALYTICS_ID: isServer ? process.env.GOOGLE_ANALYTICS_ID : '',
      WEBSOCKET_HOST: isServer
        ? process.env.WEBSOCKET_HOST ||
          (isProd ? WebsocketProdHost : WebbaseHost)
        : '',
      SENTRY_ADDON_DSN: isServer
        ? process.env.SENTRY_ADDON_DSN
        : 'https://b55344c5f7ec45d193fc8cf952460b63@o522466.ingest.sentry.io/5880196',
      AMPLITUDE_API_KEY: isServer
        ? process.env.AMPLITUDE_API_KEY
        : '857a897139d463fe34e62676d2cb2f8f',
    },
  };

  return data;
};

export function ErrorBoundary() {
  const error = useRouteError();

  if (!isProd) {
    console.error('Root ERROR:', error);
  }

  // Handle (webbase) API errors in a loader by throwing a response with the right status code
  // This triggers the root ErrorBoundary to run again, with a RouteError, and we render the right error page
  if (isServer && error instanceof HttpApiRequestError) {
    throw new Response(error.statusText, { status: error.statusCode });
  }

  // we can't use `useLoaderData` if the current error occured in a LoaderFunction, so we hack in some values instead
  const data = getPlaceholderLoaderData();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <Document data={data} styles={Styles.app} title={'Not found'}>
        <NotFoundPage />
      </Document>
    );
  }
  if (isRouteErrorResponse(error) && error.status === 403) {
    return (
      <Document data={data} styles={Styles.app} title={'Permission denied'}>
        <PermissionDeniedPage error={error} />
      </Document>
    );
  }
  if (isRouteErrorResponse(error) && error.status === 401) {
    return (
      <Document data={data} styles={Styles.app} title={'Login required'}>
        <LoginRequiredPage error={error} />
      </Document>
    );
  }

  // see: https://docs.sentry.io/platforms/javascript/guides/remix/manual-setup/#v2-errorboundary
  captureRemixErrorBoundaryError(error);

  return (
    <Document data={data} styles={Styles.app} title={'Error'}>
      <SiteLayout>
        <h1>Sorry something went wrong!</h1>
        <p className="my-4">
          Oops, something unexpected happened. We have been notified and will
          look into it.
        </p>
      </SiteLayout>
    </Document>
  );
}
