import { useEffect } from 'react';

export const MetaPixel = () => {
  useEffect(() => {
    // this prevents hydration error
    const script = document.createElement('script');
    script.src = `/scripts/pixel.js`;
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=442690404079539&ev=PageView&noscript=1"
        />
      </noscript>
    </>
  );
};
