import { SiteLayout } from './site/SiteLayout';

export default function NotFoundPage() {
  return (
    <SiteLayout>
      <div className="max-w-7xl mx-auto py-2 lg:py-8">
        <h1 className="text-4xl font-bold">404</h1>
        <p>Sorry, page not found.</p>
      </div>
    </SiteLayout>
  );
}
