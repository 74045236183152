import { ErrorResponse } from '@remix-run/server-runtime';
import { SiteLayout } from './site/SiteLayout';

type Props = {
  error: ErrorResponse;
};

export default function LoginRequiredPage(props: Props) {
  const { error } = props;
  return (
    <SiteLayout>
      <div className="max-w-7xl mx-auto py-2 lg:py-8">
        <h1 className="text-4xl font-bold">Login required ({error.status})</h1>
        {error.data && <p>{JSON.stringify(error.data)}</p>}
      </div>
    </SiteLayout>
  );
}
