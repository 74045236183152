// Unfortunately I've had to duplciate this file into both /app and /server
export type Resource = {
  rel: 'preload' | 'preconnect' | 'dns-prefetch';
  as?: 'style' | 'script' | 'font';
  path: string;
};

export const PrefetchResources: Resource[] = [
  {
    path: 'https://www.google-analytics.com/',
    rel: 'preconnect',
  },
  {
    path: 'https://www.googletagmanager.com/',
    rel: 'preconnect',
  },
  {
    path: 'https://www.facebook.com/',
    rel: 'preconnect',
  },
  {
    path: 'https://amp.syncwith.com/',
    rel: 'dns-prefetch',
  },
];
