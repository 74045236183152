import { getApp, WebAppName } from '../../lib/env';

/**
 * This code is used client side, designed to work even when <Scripts/> are not emitted, eg no client side react app
 */
export const EventTracking = () => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
          (function(){
            function trackScroll(depth, first, scrollHeight) {
              amplitude.getInstance().logEvent('scroll', {
                first: first,
                scrollHeight: scrollHeight,
                depth: Math.floor(depth),
                path: window.location.pathname,
                queryString: window.location.search,
                hash: window.location.hash,
                app: '${WebAppName}',
                webapp: '${getApp()}',
              });
            }
            let trackedScroll = false;
            let trackedScroll50 = false;
            let trackedScroll75 = false;
            let trackedScroll90 = false;
            document.addEventListener('scroll', function() {
              if (typeof amplitude === 'undefined') {
                console.warn('Amplitude not present (yet?) to trackScroll');
                return;
              }
              var h = document.documentElement,
              b = document.body,
              st = 'scrollTop',
              sh = 'scrollHeight';

              var scrollHeight = (h[st]||b[st]);
              var percent = scrollHeight / ((h[sh]||b[sh]) - h.clientHeight) * 100;

              // round small percentages up
              if (percent > 0 && percent < 1) percent = 1;

              if (!trackedScroll) {
                trackedScroll = true;
                trackScroll(percent, true, scrollHeight);
              } else if (percent >= 50 && !trackedScroll50) {
                trackedScroll50 = true;
                trackScroll(percent, false, scrollHeight);
              } else if (percent >= 75 && !trackedScroll75) {
                trackedScroll75 = true;
                trackScroll(percent, false, scrollHeight);
              } else if (percent >= 90 && !trackedScroll90) {
                trackedScroll90 = true;
                trackScroll(percent, false, scrollHeight);
              }
            });
          })();

          function trackEvent(event, properties) {
            var label = 'label' in properties ? properties.label : undefined;
            label = label || Object.keys(properties).length > 0 ? JSON.stringify(properties) : undefined;

            // GA3 tracking
            /* window.ga('send', 'event', event.category, event.name, label); */

            properties.app = '${WebAppName}';
            properties.webApp = '${getApp()}';
            properties.path = window.location.pathname;

            // Amplitude
            amplitude.getInstance().logEvent(event.category + '.' + event.name, properties);
          }

          function handleExternalClick(url, redirectedUrl, source, eventCategory, eventName) {
            var opened = window.open(redirectedUrl, '_blank');
            // GA3 tracking
            /*window.ga(
              'send',
              'event',
              eventCategory,
              eventName,
              url,
              {
                transport: 'beacon',
                hitCallback: function() {
                  if (!opened) {
                    window.location.href = redirectedUrl;
                  }
                }
              }
            );*/
          }
        `,
      }}
    />
  );
};
