import { DeviceIdCookieName } from '../../lib/deviceId';
import { getAbsoluteUrl } from '../../lib/env';

export const DeviceId = () => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
        function track(deviceId) {
          try {
            var http = new XMLHttpRequest();

            var url = '${getAbsoluteUrl('/api/entrance')}';
            http.open('POST', url, true);
            http.setRequestHeader('Content-type', 'application/json');
            http.onreadystatechange = function() {
              if (http.readyState == 4 && http.status !== 200) {
                console.error('Failed to track entrance: ' + http.responseText);
              }
            };
            http.send(JSON.stringify({
              deviceId: deviceId,
              path: window.location.pathname || '',
              queryString: window.location.search || '',
              referrer: document.referrer || ''
            }));
          } catch (err) {
            console.error('Failed to track entrance: ' + err);
          }
        }
        function mkDeviceId() {
          try {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
              (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
          } finally {          
            var S4 = function() {
              return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
            };
            return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
          }
        }

        function getDeviceId() {
          let cookieDeviceId = document.cookie
            .split('; ')
            .find((row) => row.startsWith('${DeviceIdCookieName}='))
            ?.split('=')[1];
          if (cookieDeviceId) {
            return cookieDeviceId;
          }
          return localStorage.getItem('sw_${DeviceIdCookieName}');
        }

        function saveDeviceId(deviceId) {
          // Store (or update) the deviceId in the cookie
          var expires = new Date();
          expires.setTime(expires.getTime() + (90 * 24 * 60 * 60 * 1000));
          document.cookie = '${DeviceIdCookieName}=' + deviceId + '; path=/; expires=' +  expires.toUTCString() + '; SameSite=None; Secure';

          window.env.DEVICE_ID = deviceId;

          localStorage.setItem('sw_${DeviceIdCookieName}', deviceId);
        }

        let deviceId = getDeviceId();

        if (!deviceId) {
          deviceId = mkDeviceId();
          track(deviceId);
        }
        
        saveDeviceId(deviceId);

        `,
      }}
    />
  );
};
