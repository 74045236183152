export const GoogleAdsCookieName = 'sda-elgoog';

/**
 * We grab the gclid from the query string, and put it in a cookie, so that we can access it on the server
 */
export const GoogleAdsCookie = () => {
  return (
    <script
      async
      dangerouslySetInnerHTML={{
        __html: `    
          (function(){
            var qs = (function(a) {
                if (a == "") return {};
                var b = {};
                for (var i = 0; i < a.length; ++i)
                {
                    var p=a[i].split('=', 2);
                    if (p.length == 1)
                        b[p[0]] = "";
                    else
                        b[p[0]] = decodeURIComponent(p[1].replace(/\\+/g, " "));
                }
                return b;
            })(window.location.search.substr(1).split('&'));
    
            const gclid = qs['gclid'];
            if (gclid) {        
              var expires = new Date();
              expires.setTime(expires.getTime() + (90 * 24 * 60 * 60 * 1000));
              document.cookie = '${GoogleAdsCookieName}=' + gclid + '; path=/; expires=' +  expires.toUTCString() + '; SameSite=Strict; Secure';
            }
          })();          
          `,
      }}
    />
  );
};
