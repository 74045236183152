export const FirstPromoter = () => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
    (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"8gu04qpb"}); 
fpr("click");
    `,
        }}
      />
      <script src="https://cdn.firstpromoter.com/fpr.js" async />
    </>
  );
};
